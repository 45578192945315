(function (canopyCore) {
	"use strict";

	canopyCore.directive("canopyCoreFieldSelect", function (utilities, controllerLinker, $injector, $filter, canopyFormFields) {
		return {
			restrict: "A",
			replace: true,
			require: "^canopyCoreFormContext",
			controller: function ($scope) {
				$scope.model = {
					internal: null
				};
				
				$scope.uuid = utilities.getUUID();

				$scope.validation = {
					active: false,
					passed: true,
					message: ""
				};

				$scope.reference = function () {
					return $scope.fieldLabel;
				};

				$scope.switchToDisable = function (element) {
					$scope.fieldDisabledOnSubmit = element ? true : false;
				};

				$scope.validate = function () {
					$scope.validation.active = true;
					$scope.validation.passed = true;
					$scope.validation.message = "";
					$scope.fieldError = false;
					if ($scope.fieldRequired) {
						if ($scope.fieldModel) {
							// empty
						} else {
							$scope.validation.passed = false;
							$scope.validation.message = canopyFormFields.getFieldValidationMsg(canopyFormFields.FIELD_VALIDATION_MSG_TYPE_REQUIRED);
							$scope.fieldError = true;
						}
					}

					if (!$scope.validation.passed && typeof $scope.onValidationFailed === "function") {
						$scope.onValidationFailed();
					}

					return $scope.validation.passed;
				};

				$scope.$watch("fieldOptions", function (options) {
					if ($scope.fieldOptions instanceof Array && $scope.fieldOptions.length) {
						updateModels();
					}
				});

				$scope.$watch("model.internal", function (value) {
					if (value) {
						if ($scope.fieldOptionsValueProperty && $scope.fieldOptionsReduceValue) {
							$scope.fieldModel = value[$scope.fieldOptionsValueProperty];
						}
						else {
							$scope.fieldModel = value;
						}
					}
					else if ($scope.fieldAllowNullSelection) {
						$scope.fieldModel = value;
					}
				});

				$scope.$watch("fieldModel", function (value) {
					if ($scope.validation.active) {
						$scope.validate();
					}

					// Emit a custom event to handle the data from <select>
					// Pass the selected value and any other emit data to any handlers listening to this...
					var emitData = $scope.fieldEmitData ? angular.fromJson($scope.fieldEmitData) : {} || {};
					emitData.value = value;

					$scope.$emit("dropdown-selection", emitData);
					if ($scope.fieldOptionsTitleProperty &&
						$scope.model.internal &&
						$scope.model.internal[$scope.fieldOptionsTitleProperty] &&
						$scope.model.internal[$scope.fieldOptionsTitleProperty] !== $scope.fieldModel) {
						updateModels();
					}

					if ($scope.fieldAllowNullSelection && value) {
						updateModels();
					}

					if ($scope.onFieldChange) {
						$scope.onFieldChange({ value: $scope.fieldModel });
					}

				});

				function updateModels() {
					var property = $scope.fieldOptionsValueProperty;
					var value;
					var model;
					if ($scope.fieldModel) {
						value = $scope.fieldModel[property] || $scope.fieldModel;

						model = $scope.fieldOptions.find(function (element) {
							if (property) {
								return element[property] === value;
							}
							else {
								return element === $scope.model.internal;
							}
						});

						if (model === null || !model && $scope.fieldAutoSelect) {
							model = $scope.fieldOptions[0];
						} else {
							var findModel = $scope.fieldOptions.find(function (option) {
								if (property) {
									return option[property] === $scope.fieldModel[property];
								} else {
									return option === $scope.fieldModel;
								}
							});

							if (findModel) {
								model = findModel;
							}
						}

						if (model && angular.isObject(model)) {
							model.selected = true;
						}

						$scope.model.internal = model;
					} 	else {
						if ($scope.fieldAutoSelect) {
							$scope.model.internal = $scope.fieldOptions[0];
						}
						else if (typeof($scope.fieldAutoSelectIndex) !== "undefined") {
							if ($scope.fieldOptions[$scope.fieldAutoSelectIndex]) {
								$scope.model.internal = $scope.fieldOptions[$scope.fieldAutoSelectIndex];
							} else {
								$scope.model.internal = null;
							}
						}
					}
				}
				// eslint-disable-next-line no-unused-vars
				var setPlaceholder = function  () {
					if ($scope.fieldDefaultText) {
						$scope.fieldDefaultPlaceholder = $scope.fieldDefaultText;
					} else {
						$scope.fieldDefaultPlaceholder =  canopyFormFields.getFieldDropdownPlaceholder();
					}
				}();

				$scope.onChange = function (fieldModel) {
					// item.selected and $scope.fieldModel.selected required by canopy-core-field-single-select-group.directive.js / canopyCoreFieldSingleSelectGroup
					// if item / fieldValue is an object only attempt to add a '.selected' prop in that case...
					// Situations where it may not be a complex 'Object' is where you state fields represented by a dropdown and the values are then simple strings / numbers, for example

					$scope.fieldOptions.forEach(function (item) {
						if (angular.isObject(item)) {
							item.selected = false;
						}
					});

					if (fieldModel) {
						if (angular.isObject(fieldModel)) {
							fieldModel.selected = true;
						}
					}
					
				};
			},
			scope: {
				fieldModel: "=",
				fieldDescription: "@",
				fieldLabel: "@",
				fieldRequired: "=",
				fieldDisabled: "=",
				fieldReadOnly: "=?",
				fieldOptions: "=",
				fieldOptionsTitleProperty: "@",
				fieldOptionsValueProperty: "@",
				fieldOptionsReduceValue: "=",
				fieldAutoSelect: "=",
				fieldAutoSelectIndex: "=",
				fieldTestId: "@",
				fieldDefaultText: "@",
				fieldEmitData: "@?",
				fieldError: "=?",
				fieldHideDefaultOption: "=?",
				labelDecorator: "=",
				labelDecoratorVal: "=",
				onValidationFailed: "&?",
				onFieldChange: "&?",
				fieldAllowNullSelection: "=",
				allowTooltip: "=?"
			},
			link: controllerLinker,
			templateUrl: Luma.paths.context + "/system/mantle/components/canopyCore/directives/form-field/canopy-form-field-select.template.html"
		};
	});
})(canopyCore);
