(function(angular, canopyCore) {
	"use strict";

	canopyCore.service("canopyFormFields", function ($injector, $filter) {
	
		const FIELD_PRIMITIVE_NUMBER = "Number";
		const FIELD_PRIMITIVE_STRING = "String";
		const FIELD_PRIMITIVE_DATE = "Date";
		const FIELD_PRIMITIVE_BOOLEAN = "Boolean";

		const FIELD_VALIDATION_ERR_MSG_REQUIRED = "Required field";
		const FIELD_VALIDATION_ERR_MSG_PATTERN = "Invalid value";

		const FIELD_DROPDOWN_PLACEHOLDER = "Select an option...";

		const FIELD_VALIDATION_MSG_TYPE_REQUIRED = "Required";
		const FIELD_VALIDATION_MSG_TYPE_PATTERN = "Pattern";

		const EVENT_REFRESH_DROPDOWN_OPTIONS = "Refresh dropdown options";
		const EVENT_SELECT_DROPDOWN_OPTION = "Select dropdown option";

		function getFieldDropdownPlaceholder() {
			if ($injector.has("canopyLocalise" + "Filter")) {
				return $filter("canopyLocalise")("core.directives.dropdown.placeholder");
			}

			return FIELD_DROPDOWN_PLACEHOLDER;
		}

		function getFieldValidationMsg(type) {
			if (type === FIELD_VALIDATION_MSG_TYPE_REQUIRED) {
				if ($injector.has("canopyLocalise" + "Filter")) {
					return $filter("canopyLocalise")("core.info.required_field");
				}

				return FIELD_VALIDATION_ERR_MSG_REQUIRED;
			} else if (type === FIELD_VALIDATION_MSG_TYPE_PATTERN) {
				if ($injector.has("canopyLocalise" + "Filter")) {
					return $filter("canopyLocalise")("core.info.invalid_value");
				}

				return FIELD_VALIDATION_ERR_MSG_PATTERN;
			}

			return FIELD_VALIDATION_ERR_MSG_REQUIRED;
		}

		function fieldIsFalsy(fieldPrimitive, fieldValue) {
			if (fieldPrimitive === FIELD_PRIMITIVE_NUMBER) {
				if (fieldValue === 0) {
					return false;
				}
			} 
			//checkbox unchecked is "false"
			if (fieldPrimitive === FIELD_PRIMITIVE_BOOLEAN && fieldValue === "false") {
				return true;
			}
			if (!fieldValue || fieldValue === undefined || fieldValue === null || fieldValue === "") {
				return true;
			}

			return false;
		}
	
		function fieldIsTruthy(fieldPrimitive, fieldValue) {
			if (fieldValue) {
				return true;
			}

			return false;
		}
		
		return {
			getFieldValidationMsg: getFieldValidationMsg,
			getFieldDropdownPlaceholder: getFieldDropdownPlaceholder,
			FIELD_PRIMITIVE_NUMBER: FIELD_PRIMITIVE_NUMBER,
			FIELD_PRIMITIVE_STRING: FIELD_PRIMITIVE_STRING,
			FIELD_PRIMITIVE_DATE: FIELD_PRIMITIVE_DATE,
			FIELD_PRIMITIVE_BOOLEAN: FIELD_PRIMITIVE_BOOLEAN,
			FIELD_VALIDATION_ERR_MSG_REQUIRED: FIELD_VALIDATION_ERR_MSG_REQUIRED,
			FIELD_VALIDATION_ERR_MSG_PATTERN: FIELD_VALIDATION_ERR_MSG_PATTERN,
			FIELD_VALIDATION_MSG_TYPE_REQUIRED: FIELD_VALIDATION_MSG_TYPE_REQUIRED,
			FIELD_VALIDATION_MSG_TYPE_PATTERN: FIELD_VALIDATION_MSG_TYPE_PATTERN,
			fieldIsFalsy: fieldIsFalsy,
			fieldIsTruthy: fieldIsTruthy,
			EVENT_REFRESH_DROPDOWN_OPTIONS: EVENT_REFRESH_DROPDOWN_OPTIONS,
			EVENT_SELECT_DROPDOWN_OPTION: EVENT_SELECT_DROPDOWN_OPTION
		};
	});
})(angular, canopyCore);

