(function (canopyCore) {
	"use strict";

	canopyCore.directive("canopyCoreFieldCustom", function () {
		return {
			restrict: "A",
			replace: true,
			require: "^canopyCoreFormContext",
			transclude: true,
			controller: function ($scope, $element, $attrs, $timeout, $compile) {
				if ($scope.directive) {
					$timeout(function () {
						var wrapper = $element.find(".directive-wrapper");
						
						if (wrapper) {
							wrapper.attr($scope.directive, "");
							$compile(wrapper)($scope);
						}
					}, 1);
				}
			},
			scope: {
				asset: "=",
				directive: "@",
				fieldModel: "=",
				fieldLabel: "@",
				fieldDescription: "@",
				fieldRequired: "="
			},
			templateUrl: Luma.paths.context + "/system/mantle/components/canopyCore/directives/form-field/canopy-form-field-custom.template.html"
		};
	});
})(canopyCore);
