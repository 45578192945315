"use strict";

canopyCore.factory("canopyGlossaries", ["lumaAPI", "$q", "$http", function (lumaAPI, $q, $http) {
	var _glossaries = {};
	var headers = headers || {
		"Content-Type": "application/x-www-form-urlencoded"
	};
	headers["X-Luma-Session-Validation-Token"] = Luma.validationToken;

	var getGlossary = function (signature) {
		var defer = $q.defer();
		if (_glossaries[signature]) {
			defer.resolve(angular.copy(_glossaries[signature]));
		} else {

			$http({
				url: Luma.paths.context + "/servlet/glossary/" + signature,
				method: "GET",
				headers: headers
			}).then(function(response) {
				_glossaries[signature] = response.data;
				defer.resolve(angular.copy(_glossaries[signature]));
			}, function(errorResponse) {
				// errorResponse may be a 404 page, which is not meaningful to return, even when we reject...
				defer.resolve();
			});
		}
		return $q.when(defer.promise);
	};

	return {
		getGlossary: getGlossary
	};
}]);