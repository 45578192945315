"use strict";

canopyCore.service("canopyAutopopulation", function (utilities, $injector, lumaConfig) {
	var user = Luma.user;


	var populators = {
		"com.intrepia.canopy.user.name": function () {
			if (utilities.getPropVal(user, "contact")) {
				return "".concat(user.forename, " ", user.surname);
			}
		},
		"com.intrepia.canopy.user.email": function () {
			if (utilities.getPropVal(user, "contact.emails")) {
				var primeEmail = user.contact.emails.find(function (email) {
					return email.prime;
				});
				if (primeEmail) {
					return primeEmail.value;
				} else {
					return user.email;
				}
			}
		},
		"com.intrepia.canopy.user.phone": function () {
			if (utilities.getPropVal(user, "contact.numbers")) {
				var primeNumber = user.contact.numbers.find(function (number) {
					return number.prime;
				});
				
				if (primeNumber) {
					return primeNumber.value;
				}
			}
		},
		"com.intrepia.canopy.domain.attribute": function (autopopulationMetadata, asset) {
			var canopySession = $injector.get("canopySession", "canopyAutopopulation");
			var lumaAPI = $injector.get("lumaAPI", "canopyAutopopulation");
			var settings = {};
			try {
				var json = JSON.parse(autopopulationMetadata);

				if (json) {
					settings = json;
				}
			} catch (e) {
				console.warn(e);
			}

			if (angular.equals({}, settings)) {
				return "";
			}

			var domainID;
			var attributeName = settings.attribute;

			if (settings.domain === lumaConfig.variablePlaceholders.assetDomain && asset) {
				domainID = utilities.getPropVal(asset, "domainID") || utilities.getPropVal(asset, "domain-id") || utilities.getPropVal(asset, "info.domainID") || utilities.getPropVal(asset, "info.domain-id") || utilities.getPropVal(asset, "asset.domainID");	
			} else if (settings.domain === lumaConfig.variablePlaceholders.currentDomain) {
				domainID = canopySession.getActiveDomain().id;
			} else if (typeof(settings.domain) === "number") {
				domainID = settings.domain;
			} else {
				return undefined;
			}

			return lumaAPI.getDomainAttribute(domainID, attributeName);
		},
		"com.intrepia.canopy.asset.prop": function (autopopulationMetadata, asset) {
			var settings = {};
			try {
				var json = JSON.parse(autopopulationMetadata);
				if (json) {
					settings = json;
				}
			}
			catch (err) {
				console.warn(err);
			}
			
			if (settings.collection !== undefined) {
				var collection = asset[settings.collection];
				if (collection) {
					for (var i = 0; i < collection.length; i++) {
						var fieldValue = utilities.getPropVal(collection[i], settings.prop);
						if (fieldValue) {
							return fieldValue;
						}
					}
				}
			} else {
				return utilities.getPropVal(asset, settings.prop);
			}
		}
	};
	
	return {
		getPopulator: function (populator) {
			var parsedPopulator;
			
			try {
				parsedPopulator = JSON.parse(populator);

				if (parsedPopulator && parsedPopulator.type) {

					return populators[parsedPopulator.type];
				}
			} catch (e) {
				// something went wrong
			}

			// populator is a string
			return populators[populator];
		},
		setPopulator: function (name, populator) {
			populators[name] = populator;
		}
	};
});