(function (canopyCore, angular) {
	"use strict";

	canopyCore.filter("startFrom", function() {
		return function(input, start) {
			start = +start; //parse to int
			return input.slice(start);
		};
	});
    

})(canopyCore, angular);