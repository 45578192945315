(function (canopyCore) {
	"use strict";

	canopyCore.directive("canopyCoreFieldMultiSelect", function (utilities, controllerLinker, canopyFormFields) {
		return {
			restrict: "A",
			replace: true,
			require: "^canopyCoreFormContext",
			controller: function ($scope) {
				$scope.uuid = utilities.getUUID();
				
				$scope.convertedOptions = [];
				var selectedFieldOptionArray = [];
				var fieldOptionKey = "";

				$scope.validation = {
					active: false,
					passed: true,
					message: ""
				};
				var calculateSelectedTotal = function () {
					$scope.selectedOptionsTotal = $scope.convertedOptions.filter(function (option) {
						return option.selected;
					}).length; 
				};

				var initConverter = function () {
					var converterOptionsArray = [];
					fieldOptionKey = Object.keys($scope.fieldOptions)[0];
					$scope.fieldOptions[fieldOptionKey].forEach(function(elm) {

						var converterObject;

						if ($scope.fieldOptionsLabelKey) {
							converterObject = {
								"label": elm[$scope.fieldOptionsLabelKey],
								"value": elm,
								"selected": false
							};
						} else {
							converterObject = {
								"value": elm,
								"selected": false
							};
						}

						converterOptionsArray.push(converterObject);
						
					});
					
					$scope.fieldOptions[fieldOptionKey] = converterOptionsArray;
					$scope.fieldOptions = JSON.stringify($scope.fieldOptions);
					initOptions();
				};

				$scope.onChanges = function() {
					if ($scope.onFieldChange) {
						$scope.onFieldChange();
					}
				};

				var initOptions = function () {

					if (!$scope.fieldNoOptionSelectedText) {
						$scope.fieldNoOptionSelectedText = "Select Options";
					}

					if (!$scope.fieldOptionSelectedText) {
						$scope.fieldOptionSelectedText = [
							"Option Selected",
							"Options Selected"
						];
					} 

					if (!angular.isArray($scope.fieldOptionSelectedText) || 
						$scope.fieldOptionSelectedText.length !== 2 ||
						!angular.isString($scope.fieldOptionSelectedText[0]) ||
						!angular.isString($scope.fieldOptionSelectedText[1])) {
						throw new Error("The fieldOptionsSelectedText input should be an array of two strings, where the first is the singular and the second is the plural.");
					}

					if (angular.isString($scope.fieldOptions)) {
						var jsonConverter = JSON.parse($scope.fieldOptions);
						fieldOptionKey = Object.keys(jsonConverter)[0];
						$scope.convertedOptions = jsonConverter[fieldOptionKey];
					} else if (angular.isObject($scope.fieldOptions) && $scope.fieldOptions.length === undefined) {
						initConverter();
					} else {
						var arrayOfOptions = $scope.fieldOptions;
						$scope.fieldOptions = {
							"multi-select-options": arrayOfOptions
						};

						initConverter();
					}
					calculateSelectedTotal();
					concatSelectedOptions();
					emitData();
				};

				var emitData = function() {
					var dataToEmit = {
						value: getConcatenatedSelectedOptions()
					};

					$scope.$emit("dropdown-multi-selection", dataToEmit);
				};

				var getConcatenatedSelectedOptions = function() {
					return $scope.fieldOptionsLabelKey ? selectedFieldOptionArray.map(function(option) {
						return option[$scope.fieldOptionsLabelKey];
					}).join(", ") : selectedFieldOptionArray.join(", ");
				};

				var concatSelectedOptions = function () {
					var concatenateFieldOptions = [];
					selectedFieldOptionArray = [];
					$scope.convertedOptions.forEach(function(option) {
						delete option.$$hashKey;
						if (option.selected) {
							concatenateFieldOptions.push(option);
						}
					});
					concatenateFieldOptions.forEach(function(string) {
						selectedFieldOptionArray.push(string.value);
					});
					var returnFieldObject = {};
					returnFieldObject[fieldOptionKey] = $scope.convertedOptions;
					if ($scope.fieldOptionsLabelKey) {
						returnFieldObject["selected-options-array"] = selectedFieldOptionArray;
					}
					returnFieldObject["selected-options-concat"] = getConcatenatedSelectedOptions();
					returnFieldObject = concatenateFieldOptions.length === 0 ? null : returnFieldObject;
					$scope.fieldModel = returnFieldObject ? JSON.stringify(returnFieldObject) : returnFieldObject;
				};

				var clearSelectedOptions = function() {

					$scope.convertedOptions.forEach(function(option) {
						option.selected = false;
					});

					concatSelectedOptions();
					calculateSelectedTotal();

					if ($scope.onFieldChange) {
						$scope.onFieldChange();
					}

					emitData();
					
				};
                
				$scope.selectOption = function(option) {
					var findSelected = $scope.convertedOptions.findIndex(function(selected) {
						return option["value"] === selected["value"];
					});
					$scope.convertedOptions[findSelected].selected = !$scope.convertedOptions[findSelected].selected;
					concatSelectedOptions();
					calculateSelectedTotal();

					if ($scope.onFieldChange) {
						$scope.onFieldChange();
					}
					emitData();
				};

				$scope.reference = function () {
					return $scope.fieldLabel;
				};

				$scope.switchToDisable = function (element) {
					$scope.fieldDisabledOnSubmit = element ? true : false;
				};

				$scope.validate = function () {
					$scope.validation.active = true;
					$scope.validation.passed = true;
					$scope.validation.message = "";
					$scope.fieldError = false;
					if ($scope.fieldRequired) {
						if ($scope.fieldModel) {
							// empty
						} else {
							$scope.validation.passed = false;
							$scope.validation.message = canopyFormFields.getFieldValidationMsg(canopyFormFields.FIELD_VALIDATION_MSG_TYPE_REQUIRED);
							$scope.fieldError = true;
						}
					}

					if (!$scope.validation.passed && typeof $scope.onValidationFailed === "function") {
						$scope.onValidationFailed();
					}

					return $scope.validation.passed;
				};

				$scope.filterFn = function(option) {
					if (!$scope.searchFilterValue) {
						// no filtering provided
						return option;
					}

					if (!option.value) {
						return;
					}

					var lowerCaseValue = option.value.toLowerCase();
					var lowerCaseSearchValue = $scope.searchFilterValue.toLowerCase();

					if (lowerCaseValue.indexOf(lowerCaseSearchValue) !== -1) {
						return option;
					}
				};

				$scope.dropdownToggle = function() {
					$scope.searchFilterValue = null;
				};

				initOptions();

				$scope.$watch("fieldModel", function (value) {
					if (value === null && $scope.selectedOptionsTotal) {
						clearSelectedOptions();
					}
					if ($scope.validation.active) {
						$scope.validate();
					}
				});

				$scope.$watch("fieldOptions", function (value) {
					if (value.length > 0) {
						initOptions();
					}
				});
			},
			scope: {
				fieldModel: "=",
				fieldDescription: "@",
				fieldLabel: "@",
				fieldOptions: "=",
				fieldOptionsLabelKey: "@",
				fieldRequired: "=",
				fieldDisabled: "=",
				fieldError: "=?",
				fieldNoOptionSelectedText: "@?",
				fieldOptionSelectedText: "=?",
				labelDecorator: "=",
				labelDecoratorVal: "=",
				removeSearchField: "=",
				onValidationFailed: "&?",
				onFieldChange: "&?"
			},
			link: controllerLinker,
			templateUrl: Luma.paths.context + "/system/mantle/components/canopyCore/directives/form-field/canopy-form-field-multi-select.template.html"
		};
	});
})(canopyCore);
