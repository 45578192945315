canopyCore.service("canopyModals", ["$uibModal", function ($uibModal) {
	return {
		instantiate: function ( modalDefintion ) {
			var modalInstance = $uibModal.open({
				templateUrl: modalDefintion.templateUrl,
				resolve: modalDefintion.resolve,
				controller: modalDefintion.controller,
				windowClass: modalDefintion.windowClass,
				size: modalDefintion.size || null
			});
			
			return modalInstance;
		},
		definitions: {
			message: function ( content ) {
				return {
					templateUrl: Luma.paths.context + "/system/mantle/components/canopyCore/services/modals/message/modal-message.template.html",
					resolve: {
						content: function () {
							return content;
						}
					},
					controller: function ( $scope, $uibModalInstance, content ) {
						$scope.content = content;
						
						$scope.dismiss = function () {
							$uibModalInstance.dismiss();
						};
				
						$scope.confirm = function () {
							$uibModalInstance.close();
						};
					},
					handleResult: function ( result ) {
						return result;
					}
				};
			},
			serverError: function ( response ) {
				return {
					templateUrl: Luma.paths.context + "/system/mantle/components/canopyCore/services/modals/server-error/modal-server-error.template.html",
					resolve: {
						response: function () {
							return response;
						}
					},
					controller: function ( $scope, $uibModalInstance, response ) {
						$scope.response = response;
						
						$scope.dismiss = function () {
							$uibModalInstance.close();
						};
				
						$scope.confirm = function () {
							$uibModalInstance.close();
						};
					},
					handleResult: function ( result ) {
						return result;
					}
				};
			}
		}
	};
}]);