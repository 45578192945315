(function (canopyCore) {
	"use strict";

	canopyCore.directive("canopyCoreFieldRadio", function (controllerLinker, utilities, canopyFormFields) {
		return {
			restrict: "A",
			replace: true,
			require: "^canopyCoreFormContext",
			transclude: true,
			controller: function ($scope, $element) {
				$scope.uuid = utilities.getUUID();

				$scope.validation = {
					active: false,
					passed: true,
					message: ""
				};

				$scope.reference = function () {
					return $scope.fieldLabel;
				};

				$scope.switchToDisable = function (element) {
					$scope.fieldDisabledOnSubmit = element ? true : false;
				};

				$scope.validate = function () {
					$scope.validation.active = true;
					$scope.validation.passed = true;
					$scope.validation.message = "";

					var value = $scope.fieldModel;

					if ($scope.fieldRequired) {
						if (!value || value.selected !== true) {
							$scope.validation.passed = false;
							$scope.validation.message = canopyFormFields.getFieldValidationMsg(canopyFormFields.FIELD_VALIDATION_MSG_TYPE_REQUIRED);
						}
					}

					if (!$scope.validation.passed && typeof $scope.onValidationFailed === "function") {
						$scope.onValidationFailed();
					}

					return $scope.validation.passed;
				};

				$scope.$watch("fieldModel", function (value) {
					if ($scope.validation.active) {
						$scope.validate();
					}
				});

				$scope.onChange = function (fieldValue) {
					// item.selected required by canopy-core-field-single-select-group.directive / canopyCoreFieldSingleSelectGroup
					// if item / fieldValue is an object only attempt to add a '.selected' prop in that case...
					
					$scope.fieldList.forEach(function (item) {
						if (angular.isObject(item)) {
							item.selected = false;
						}
					});

					if (angular.isObject(fieldValue)) {
						fieldValue.selected = true;
					}

					if ($scope.onFieldChange) {
						$scope.onFieldChange();
					}
				};
			},
			scope: {
				fieldModel: "=",
				fieldDescription: "@",
				fieldLabel: "@",
				fieldRequired: "=",
				fieldDisabled: "=",
				fieldTestId: "@",
				fieldValue: "=",
				fieldList: "=",
				labelDecorator: "=",
				labelDecoratorVal: "=",
				onValidationFailed: "&?",
				onFieldChange: "&?"
			},
			link: controllerLinker,
			templateUrl: Luma.paths.context + "/system/mantle/components/canopyCore/directives/form-field/canopy-form-field-radio.template.html"
		};
	});
})(canopyCore);
