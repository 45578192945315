(function(canopyCore) {
	"use strict";

	canopyCore.service("eventDispatcher", function () {
		var _createEvent = function(type, detail) {
			return new CustomEvent(type, { detail: detail });
		};

		var _dispatchEvent = function(event) {
			window.dispatchEvent(event);
		};

		this.activate = function() {
			// TODO: remove function once all instances of activate have been removed from tenants
		};

		this.deactivate = function() {
			// TODO: remove function once all instances of activate have been removed from tenants
		};
		
		this.fireEvent = function(type, detail) {
			var wrapper = { data: angular.copy(detail) };
			_dispatchEvent(_createEvent(type, wrapper));
		};
	});
})(canopyCore);
