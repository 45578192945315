
(function (canopyCore) {
	"use strict";

	canopyCore.directive("canopyCoreFieldCheckbox", function (controllerLinker, utilities, canopyFormFields) {
		return {
			restrict: "A",
			replace: true,
			require: "^canopyCoreFormContext",
			transclude: true,
			controller: function ($scope) {
				$scope.uuid = utilities.getUUID();

				$scope.validation = {
					active: false,
					passed: true,
					message: ""
				};

				$scope.reference = function () {
					return $scope.fieldLabel;
				};

				var onInit = function() {
					if ($scope.fieldModel === undefined) {
						$scope.fieldModel = $scope.fieldDefaultValue !== undefined || $scope.fieldDefaultValue !== null ? $scope.fieldDefaultValue : false;
					}
				};

				$scope.switchToDisable = function (element) {
					$scope.fieldDisabledOnSubmit = element ? true : false;
				};

				$scope.validate = function () {
					$scope.validation.active = true;
					$scope.validation.passed = true;
					$scope.validation.message = "";

					var value = $scope.fieldModel;

					if ($scope.fieldRequired) {
						// value can be string 'true' or Boolean true from API...
						if (value !== true && value !== "true") {
							$scope.validation.passed = false;
							$scope.validation.message = canopyFormFields.getFieldValidationMsg(canopyFormFields.FIELD_VALIDATION_MSG_TYPE_REQUIRED);
						}
					}

					if (!$scope.validation.passed && typeof $scope.onValidationFailed === "function") {
						$scope.onValidationFailed();
					}

					return $scope.validation.passed;
				};

				onInit();

				$scope.onChanges = function () {
					if ($scope.onFieldChange) {
						$scope.onFieldChange();
					}
				};

				$scope.$watch("fieldModel", function (value) {
					if ($scope.validation.active) {
						$scope.validate();
					}
				});

				$scope.$on("$destroy", () => {
					$scope.$emit("canopyFieldDestroyed");
				});
			},
			scope: {
				fieldDefaultValue: "=",
				fieldDescription: "@",
				fieldDisabled: "=",
				fieldIndeterminate: "=?",
				fieldLabel: "@",
				fieldModel: "=",
				fieldRequired: "=",
				fieldTestId: "@",
				labelDecorator: "=",
				labelDecoratorVal: "=",
				inlineLabel: "=?",
				inheritFontSize: "=?",
				onFieldChange: "&?",
				onValidationFailed: "&?",
				typeViewFieldDisabled: "=",
				typeViewFieldHidden: "=",
				typeViewFieldNulled: "=",
				typeViewFieldRequired: "=",
				typeViewMessage: "=",
			},
			link: controllerLinker,
			templateUrl: Luma.paths.context + "/system/mantle/components/canopyCore/directives/form-field/canopy-form-field-checkbox.template.html"
		};
	});
})(canopyCore);
