(function (canopyCore) {
	"use strict";

	/**
	 * Luma config service.
	 * For things which can change and 3rd party dependencies (e.g. backend services).
	 * The actual values should probably be fed from HA configuration resource (e.g. file, database, API call)
	 * so when they change only configuration resource needs to be deployed without actual code change required.
	 */
	canopyCore.factory("lumaConfig", ["utilities", function (utilities) {
		var isDateFilter =  function (filter) {
			var filterType;

			if (typeof filter === "string") {
				filterType = filter;
			} else {
				filterType = filter.type;
			}	

			return filterType === _filterTypes.periodFilter ||
				filterType === _filterTypes.projectPeriodFilter ||
				filterType === _filterTypes.periodOrdersFilter || 
				filterType === _filterTypes.listAccountsActivePeriodFilter ||
				filterType === _filterTypes.abstractTaskListingPeriodFilter;
		};

		var _variablePlaceholders = {
			todaysDate: "todaysDate",
			actorID: "__userID__",
			userDomainID: "__userDomainID__",
			currentDomain: "__currentDomain__",
			currentSite: "__currentSite__",
			assetDomain: "__assetDomain__",
			monthPrevious: "__month-previous__",
			monthCurrent: "__month-current__",
			monthNext: "__month-next__",
			quarterPrevious: "__quarter-previous__",
			quarterCurrent: "__quarter-current__",
			quarterNext: "__quarter-next__",
			yearPrevious: "__year-previous__",
			yearCurrent: "__year-current__",
			yearNext: "__year-next__",
			intrepiaSessionID: "__intrepiaSessionID__",
			parentProjectEndDate: "__parentEndDate__",
			parentProjectStartDate:"__parentStartDate__",
			placeholderAssetID: "__placeholderAssetID__",
			getDeliveryHook: "__getDeliveryHook__"
		};

		var _arrVariablePlaceholders = [];
		Object.keys(_variablePlaceholders).forEach(function (key) {
			_arrVariablePlaceholders.push(_variablePlaceholders[key]);
		});

		const ASSET_PHASE_ACTIVE = "Active";
		const ASSET_PHASE_QUARANTINED = "Quarantined";
		const ASSET_PHASE_EXPIRED = "Expired";

		var _listingStyles = {
			page: "Page",
			spreadsheet: "Spreadsheet",
			list: "List",
			table: "Table",
			collated: "Collated",
			export: "Export",
			row: "Row",
			cell: "Cell",
			count: "Count"
		};

		var _listSortOrders = {
			ASCENDING: "Ascending",
			DESCENDING: "Descending"
		};

		var _filterTypes = {
			expirationFilter: "com.intrepia.luma.ExpirationFilter",
			virtualFilter: "com.intrepia.canopy.VirtualFilter",
			listAccountsActivePeriodFilter: "com.intrepia.luma.ListAccounts$ActivePeriodFilter",
			typeRoleFilter: "com.intrepia.luma.TypeRoleFilter",
			periodFilter: "com.intrepia.luma.PeriodFilter",
			projectPeriodFilter: "com.intrepia.luma.ProjectPeriodFilter",
			keywordFilter: "com.intrepia.luma.KeywordFilter",
			typeFilter: "com.intrepia.luma.TypeFilter",
			stateFilter: "com.intrepia.luma.StateFilter",
			importFilter: "com.intrepia.luma.ImportFilter",
			formatFilter: "com.intrepia.luma.FormatFilter",
			advancedSearchFilter: "com.intrepia.luma.AdvancedSearchFilter",
			domainFilter: "com.intrepia.luma.DomainFilter",
			periodOrdersFilter: "com.intrepia.luma.ListOrders$PeriodFilter",
			accountFilter: "com.intrepia.luma.ListProjectEstimatesByAccount$AccountFilter",
			fiscalTypeFilter: "com.intrepia.luma.ListProjectEstimatesByAccount$FiscalTypeFilter",
			typeSchemaFilter: "com.intrepia.luma.TypeSchemaFilter",
			processFilter: "com.intrepia.luma.ProcessFilter",
			stateOrdersFilter: "com.intrepia.luma.ListOrders$StateFilter",
			processOrdersFilter: "com.intrepia.luma.ListOrders$ProcessFilter",
			groupOrdersFilter: "com.intrepia.luma.ListOrders$OrderGroupFilter",
			groupRegistrationsFilter: "com.intrepia.luma.ListRegistrations$GroupFilter",
			stateBudgetsFilter: "com.intrepia.luma.ListBudgets$StateFilter",
			groupBudgetsFilter: "com.intrepia.luma.ListBudgets$GroupFilter",
			stateProjectsFilter: "com.intrepia.luma.ListProjects$ProjectStateFilter",
			groupProjectsFilter: "com.intrepia.luma.ListProjects$ProjectGroupFilter",
			campaignProjectsFilter: "com.intrepia.luma.ListProjects$ProjectCampaignFilter",
			stateCampaignsFilter: "com.intrepia.luma.ListCampaigns$CampaignStateFilter",
			groupCampaignsFilter: "com.intrepia.luma.ListCampaigns$CampaignGroupFilter",
			stateReimbursementsFilter: "com.intrepia.luma.ListBudgetReimbursements$StateFilter",
			estimateAggregationFilter: "com.intrepia.luma.EstimateAggregationFilter",
			assetCostAggregationFilter: "com.intrepia.luma.AssetCostAggregationFilter",
			ragFilter : "com.intrepia.luma.RagFilter",
			abstractTaskListingPeriodFilter: "com.intrepia.luma.AbstractTaskListing$PeriodFilter",
			abstractTaskListingFlaggedFilter: "com.intrepia.luma.AbstractTaskListing$FlaggedFilter",
			abstractTaskListingTentativeFilter: "com.intrepia.luma.AbstractTaskListing$TentativeFilter",
			abstractTaskListingColourFilter: "com.intrepia.luma.AbstractTaskListing$ColourFilter",
			abstractTaskListingStateFilter: "com.intrepia.luma.AbstractTaskListing$StateFilter",
			abstractTaskListingTeamFilter: "com.intrepia.luma.AbstractTaskListing$TeamFilter",
			abstractTaskListingKeywordFilter: "com.intrepia.luma.AbstractTaskListing$KeywordFilter",
			abstractTaskListingPriorityFilter: "com.intrepia.luma.AbstractTaskListing$PriorityFilter",		
			abstractTaskListingAssetSearchFilter: "com.intrepia.luma.AbstractTaskListing$AssetSearchFilter",
			abstractTaskListingTypeFilter: "com.intrepia.luma.AbstractTaskListing$TypeFilter",
			abstractReviewListingAssetFilter: "com.intrepia.luma.reviews.AbstractReviewListing$AssetFilter",
			abstractReviewListingParticipationFilter: "com.intrepia.luma.reviews.AbstractReviewListing$ParticipationFilter",
		};
			
		// Map luma menu node page types to default list service
		// If you specify a 'service' in the Luma menu node metadata it will have priority of the defaults listed below...
		var _defaultListServices = {
			"AccountList": "ListAccountsForDomain",
			"AssetList": "ListAssets",
			"Basket": "ListBasket",
			"BatchList": "ListBatches",
			"Collaborate": "ListUserProjects",
			"BriefList": "ListUserProjectNodes",
			"OrderList": "ListOrders",
			"ProjectList": "ListUserProjects",
			"ProjectListV2": "ListUserProjects",
			"ProjectListV3": "ListUserProjects",
			"ProofList": "proofing.ListUserProofs$ByTypeRole",
			"ShareList": "ListShares",
			"WorkflowList": "ListWorkflows",
			"TaskList": "ListAssetTasksForDomain",
			"ReviewList": "reviews.ListReviews"
		};

		// Default list parameters per listing page type and service...
		// TODO: Complete this list by checking the list controllers hard coded parameters...
		var _defaultListParameters = {
			"AccountList": {
				"ListAccountsForDomain": {
					"listing-sort": "asset-created",
					"listing-order": "Descending",
					"domainID": "__currentDomain__",
					"siteID": "__currentSite__"
				}
			},
			"AssetList": {
				"ListAssets": {
					"listing-sort": "asset-created",
					"listing-order": "Descending",
					"inherit": true,
					"available": true,
					"status": true,
					"domainID": "__currentDomain__"
				},
				"ListTemplates": {
					"listing-sort": "asset-created",
					"listing-order": "Descending",
					"domainID": "__currentDomain__"
				},
				"ListUserAdaptations": {
					"listing-sort": "asset-created",
					"listing-order": "Descending"
				}
			},
			"Basket": {
				"ListBasket": {
					"listing-limit": 30,
					"listing-style": "Page",
					"listing-order": "Descending"
				}
			},
			"BatchList": {
				"ListBatches": {
					"listing-sort": "asset-created",
					"listing-order": "Descending",
					"inherit": true,
					"domainID": "__currentDomain__"
				}
			},
			"BriefList": {
				"ListUserProjects": {
					"listing-sort": "asset-created",
					"listing-order": "Descending",
					"listing-limit": 40,
					"domainID": "__currentDomain__",
					"milestones": true
				},
				"ListUserProjectNodes": {
					"listing-sort": "asset-created",
					"listing-order": "Descending",
					"listing-limit": 40,
					"domainID": "__currentDomain__",
					"milestones": true
				}
			},
			"Collaborate": {
				"ListUserProjects": {
					"listing-sort": "asset-created",
					"listing-order": "Descending",
					"listing-limit": 40,
					"domainID": "__currentDomain__",
					"milestones": true
				},
				"ListUserProjectNodes": {
					"listing-sort": "asset-created",
					"listing-order": "Descending",
					"listing-limit": 40,
					"domainID": "__currentDomain__",
					"milestones": true
				}
			},
			"OrderList": {
				"ListOrders": {
					"inherit": true,
					"available": true,
					"status": true,
					"tree": null,
					"listing-sort": "asset-created",
					"listing-order": "Descending",
					"domainID": "__currentDomain__"
				},
				"ListUserOrders": {
					"listing-sort": "asset-created",
					"listing-order": "Descending"
				}
			},
			"ProjectList": {
				"ListProjects": {
					"listing-sort": "asset-created",
					"listing-order": "Descending",
					"domainID": "__currentDomain__"
				},
				"ListUserProjects": {
					"listing-sort": "asset-created",
					"listing-order": "Descending",
					"domainID": "__currentDomain__"
				},
				"ListUserProjectNodes": {
					"listing-sort": "asset-created",
					"listing-order": "Descending",
					"domainID": "__currentDomain__"
				},
				"ListProjectEstimatesByAccount": {
					"listing-sort": "asset-created",
					"listing-order": "Descending",
					"domainID": "__currentDomain__"
				}
			},
			"ProjectListV2": {
				"ListProjects": {
					"listing-sort": "asset-created",
					"listing-order": "Descending",
					"domainID": "__currentDomain__"
				},
				"ListUserProjects": {
					"listing-sort": "asset-created",
					"listing-order": "Descending",
					"domainID": "__currentDomain__"
				},
				"ListUserProjectNodes": {
					"listing-sort": "asset-created",
					"listing-order": "Descending",
					"domainID": "__currentDomain__"
				}
			},
			"ProjectListV3": {
				"ListProjects": {
					"listing-sort": "asset-created",
					"listing-order": "Descending",
					"domainID": "__currentDomain__"
				},
				"ListUserProjects": {
					"listing-sort": "asset-created",
					"listing-order": "Descending",
					"domainID": "__currentDomain__"
				},
				"ListUserProjectNodes": {
					"listing-sort": "asset-created",
					"listing-order": "Descending",
					"domainID": "__currentDomain__"
				},
				"ListPlanSummariesForProject": {
					"listing-sort": "asset-created",
					"listing-order": "Descending",
					"domainID": "__currentDomain__"
				}
			},
			"ProofList": {
				"proofing.ListUserProofs$ByTypeRole": {
					"listing-style": "Page",
				},
			},
			"ShareList": {
				"ListShares": {
					"listing-sort": "share-created",
					"listing-order": "Descending",
					"listing-limit": 20
				}
			},
			"WorkflowList": {
				"ListWorkflows": {
					"includeSubdomains": true,
					"listing-order": "Descending",
					"domainID": "__currentDomain__"
				},
				"ListUserWorkflows": {
					"includeSubdomains": true,
					"listing-order": "Descending",
					"domainID": "__currentDomain__"
				}
			},
			"TaskList": {
				"ListAssetTasksForDomain": {
					"includeSubdomains": true,
					"listing-order": "Descending",
					"domainID": "__currentDomain__",
					"listing-limit": 20
				}
			},
			"ReviewList": {
				"reviews": {
					"ListReviews": {
						"listing-sort": "review-created",
						"listing-order": "Descending",
						"listing-limit": 40,
						"domainID": "__currentDomain__"
					}
				}
			}
		};

		var _getDefaultListParameters = function (type, service) {
			return utilities.getPropVal(_defaultListParameters, type + "." + service) || {};
		};

		var _getPlaceholderPeriod = function(placeholder) {

			var parsed = placeholder.replace(/__/g, "").split("-");

			// parsed[0] = "quarter", "month", "year", "week"
			// parsed[1] = "previous", "current", "next"

			return parsed;
		};

		var _replacePeriodPlaceholder = function(placeholder, fromUntil) {
			var period = _getPlaceholderPeriod(placeholder);
			if (period[1] === "current") {
				placeholder = moment();
			} else if (period[1] === "next") {
				placeholder = moment().add(1, period[0]);
			} else if (period[1] === "previous") {
				placeholder = moment().subtract(1, period[0]);
			} else {
				// Not a placeholder string
				return placeholder;
			}

			if (fromUntil === "from") {
				placeholder = placeholder.startOf(period[0]);
			} else if (fromUntil === "until") {
				placeholder = placeholder.endOf(period[0]);
			}

			placeholder = placeholder.format(Dates.formats.universal);
			
			return placeholder;
		};

		var _parseFilterPlaceholders = function(filter) {
			if (filter.from) {
				if (_arrVariablePlaceholders.indexOf(filter.from) !== -1); {
					filter.from = _replacePeriodPlaceholder(filter.from, "from");
				}
			}

			if (filter.until) {
				if (_arrVariablePlaceholders.indexOf(filter.until) !== -1) {
					filter.until = _replacePeriodPlaceholder(filter.until, "until");
				}
			}
			
			return filter;
		};

		return {
			isDateFilter: isDateFilter,
			filterTypes: _filterTypes,
			listingStyles: _listingStyles,
			ASSET_PHASE_ACTIVE: ASSET_PHASE_ACTIVE,
			ASSET_PHASE_QUARANTINED: ASSET_PHASE_QUARANTINED,
			ASSET_PHASE_EXPIRED: ASSET_PHASE_EXPIRED,
			filterRequestType: {
				initial: "initial",
				secondary: "secondary"
			},
			typeaheads: {
				typeRoleFilter: "TypeRole",
				keywordFilter: {
					shortName: "Keyword",
					filter: _filterTypes.keywordFilter
				},
				typeFilter: {
					shortName: "Type",
					filter: _filterTypes.typeFilter
				},
				stateFilter: {
					shortName: "State",
					filter: _filterTypes.stateFilter
				},
				importFilter: {
					shortName: "Import",
					filter: _filterTypes.importFilter
				},
				formatFilter: {
					shortName: "Format",
					filter: _filterTypes.formatFilter
				},
				advancedSearchFilter: {
					shortName: "AdvancedSearch",
					filter: _filterTypes.advancedSearchFilter
				},
				domainFilter: {
					shortName: "Domain",
					filter: _filterTypes.domainFilter
				},
				typeSchemaFilter: {
					shortName: "Schema",
					filter: _filterTypes.typeSchemaFilter
				},
				accountFilter: "Account",
				abstractTaskListingKeywordFilter: {
					shortName: "abstractTaskListingKeywordFilter",
					filter: _filterTypes.abstractTaskListingKeywordFilter
				},
				abstractTaskListingPeriodFilter: {
					shortName: "abstractTaskListingPeriodFilter",
					filter: _filterTypes.abstractTaskListingPeriodFilter
				},
				abstractTaskListingFlaggedFilter: {
					shortName: "Flagged",
					filter: _filterTypes.abstractTaskListingFlaggedFilter
				},
				abstractTaskListingTentativeFilter: {
					shortName: "Tentative",
					filter: _filterTypes.abstractTaskListingTentativeFilter
				},
				abstractTaskListingColourFilter: {
					shortName: "Colour",
					filter: _filterTypes.abstractTaskListingColourFilter
				},
				abstractTaskListingStateFilter: {
					shortName: "abstractTaskListingStateFilter",
					filter: _filterTypes.abstractTaskListingStateFilter
				},
				abstractTaskListingTypeFilter: {
					shortName: "abstractTaskListingTypeFilter",
					filter: _filterTypes.abstractTaskListingTypeFilter
				},
				abstractTaskListingTeamFilter: {
					shortName: "Team",
					filter: _filterTypes.abstractTaskListingTeamFilter
				},
				abstractTaskListingPriorityFilter: {
					shortName: "Priority",
					filter: _filterTypes.abstractTaskListingPriorityFilter
				},
				abstractTaskListingAssetSearchFilter: {
					shortName: "AssetSearchFilter",
					filter: _filterTypes.abstractTaskListingAssetSearchFilter
				},
				abstractReviewListingAssetFilter: {
					shortName: "abstractReviewListingAssetFilter",
					filter: _filterTypes.abstractReviewListingAssetFilter
				},
				abstractReviewListingParticipationFilter: {
					shortName: "abstractReviewListingParticipationFilter",
					filter: _filterTypes.abstractReviewListingParticipationFilter
				}
			},
			variablePlaceholders: _variablePlaceholders,
			timeline: {
				milestones: {
					status: {
						inProgress: "progress",
						completed: "completed",
						late: "late"
					}
				}
			},
			defaultListServices: _defaultListServices,
			getDefaultListParameters: _getDefaultListParameters,
			parseFilterPlaceholders: _parseFilterPlaceholders,
			listSortOrders: _listSortOrders
		};
	}]);

})(canopyCore);
