(function (canopyCore) {
	"use strict";

	canopyCore.directive("canopyCoreFieldInput", function (canopyAutopopulation, canopyValidation, $timeout, controllerLinker, utilities, $q, canopyFormFields) {
		return {
			restrict: "A",
			replace: true,
			require: "^canopyCoreFormContext",
			transclude: true,
			controller: function ($scope) {
				$scope.uuid = utilities.getUUID();

				$scope.validation = {
					active: false,
					passed: true,
					message: ""
				};

				$scope.reference = function () {
					return $scope.fieldLabel;
				};

				$scope.onFieldFocus = function () {
					if ($scope.fieldFocused) {
						$scope.fieldFocus = true;
					}
				};

				$scope.onFieldBlur = function () {
					if ($scope.fieldFocused) {
						$timeout(function () {
							$scope.fieldBlur = false;
						}, 100);
					}
				};

				if ($scope.fieldAutopopulation) {
					var fieldType = $scope.fieldType || "text";

					var requiresPopulation = (fieldType === "text" && $scope.fieldModel === undefined || $scope.fieldModel === "") ||
						(fieldType === "number" && isNaN($scope.fieldModel));

					if (requiresPopulation) {
						var populator = canopyAutopopulation.getPopulator($scope.fieldAutopopulation);

						if (populator) {
							$q.when(populator($scope.fieldAutopopulation, $scope.asset), function(successResponse) {
								// Successfully resolved promise or handled return value...

								// TODO: Handle successResponse a bit more neatly?
								// successResponse = a direct return value from populator
								// successResponse.data = a return value from lumaAPI
								$scope.fieldModel = successResponse && successResponse.data ? successResponse.data : successResponse;
								if (angular.isObject($scope.fieldModel)) {
									$scope.fieldModel = null;
								}
							}, function(errorResponse) {
								console.warn("canopyCoreFieldInput: handled autopopulation error response: ", errorResponse);
								// fail silently
							});
						}
					}
				}

				$scope.switchToDisable = function (element) {
					$scope.fieldDisabledOnSubmit = element ? true : false;
				};

				$scope.validate = function () {
					$scope.validation.active = true;
					$scope.validation.passed = true;
					$scope.validation.message = "";

					var value = $scope.fieldModel;
					var validator;
					var regex;

					if ($scope.fieldValidation) {
						validator = canopyValidation.getValidator($scope.fieldValidation);

						if (validator) {
							$scope.validation = validator(value);
						}
						else {
							console.warn("ERROR: Validator not found - " + $scope.fieldValidation);
						}
					}

					if ($scope.fieldValidationPattern) {
						regex = null;
						validator = null;

						try {
							regex = new RegExp("^" + $scope.fieldValidationPattern + "$");
						}
						catch (e) {
							regex = null;
						}

						if (regex) {
							if (value && regex.test(value) || (!value && (!$scope.fieldRequired && !$scope.typeViewFieldRequired))) {
								//empty
							}
							else {
								$scope.validation.passed = false;
								$scope.validation.message = canopyFormFields.getFieldValidationMsg(canopyFormFields.FIELD_VALIDATION_MSG_TYPE_PATTERN);
							}
						}
						else {
							// empty
						}
					}

					if ($scope.fieldRequired || $scope.typeViewFieldRequired) {
						if (!value || value === undefined || value.length === 0) {
							$scope.validation.passed = false;
							$scope.validation.message = canopyFormFields.getFieldValidationMsg(canopyFormFields.FIELD_VALIDATION_MSG_TYPE_REQUIRED);
						}
					}

					if (!$scope.validation.passed && typeof $scope.onValidationFailed === "function") {
						$scope.onValidationFailed();
					}

					return $scope.validation.passed;
				};

				$scope.$watch("fieldModel", function (value) {
					if ($scope.validation.active) {
						$scope.validate();
					}
				});

				$scope.onChanges = function(value) {
					if ($scope.onFieldChange) {
						$scope.onFieldChange({ value: value });
					}
				};

				$scope.$on("$destroy", () => {
					$scope.$emit("canopyFieldDestroyed");
				});
			},
			scope: {
				asset: "=",
				fieldAllowResize: "@",
				fieldModel: "=",
				fieldLabel: "@",
				fieldDescription: "@",
				fieldRequired: "=",
				fieldPlaceholder: "@",
				fieldDisabled: "=",
				fieldHidden: "=",
				fieldReadonly: "=",
				fieldType: "@",
				fieldRows: "=",
				fieldAutopopulation: "@",
				fieldValidation: "@",
				fieldValidationPattern: "@",
				fieldTestId: "@",
				fieldStep: "=",
				fieldFocus: "=",
				fieldBlur: "=",
				fieldFocused: "=",
				labelDecorator: "=",
				labelDecoratorVal: "=",
				onValidationFailed: "&?",
				onFieldChange: "&?",
				fieldSuffix: "=",
				fieldPrefix: "=",
				fieldSearchSuffix: "=?",
				fieldMinNumber: "=",
				fieldMaxNumber: "=",
				fieldMaxLength: "=",
				typeViewFieldDisabled: "=",
				typeViewFieldHidden: "=",
				typeViewFieldRequired: "=",
				typeViewMessage: "="
			},
			link: controllerLinker,
			templateUrl: Luma.paths.context + "/system/mantle/components/canopyCore/directives/form-field/canopy-form-field-input.template.html"
		};
	});
})(canopyCore);
