"use strict";

canopyCore.factory("showProjectReference", function (canopyGlossaries, $q) {
	let showProjectReference;
	const getShowProjectReference = () => { 
		const defer = $q.defer();
		if (showProjectReference !== undefined) {
			defer.resolve(showProjectReference);
		} else {
			canopyGlossaries.getGlossary("uk.co.itg.canopy.showProjectReference").then(function(response) {
				if (response["showProjectReference"] === true) {
					showProjectReference = true;
				} else {
					showProjectReference = false;
				}
				defer.resolve(showProjectReference);
			});
		}
		return $q.when(defer.promise);
	};

	return {
		getShowProjectReference: getShowProjectReference,
	};
});
