(function (canopyCore) {
	"use strict";

	canopyCore.directive("canopyCoreFieldSingleSelectGroup", function () {
		return {
			restrict: "A",
			replace: true,
			transclude: true,
			controller: function ($scope, utilities, canopySession) {
				$scope.useSelectsInsteadOfRadios = utilities.getPropVal(canopySession.getActiveSite(), "metadata.forms.use-selects-instead-of-radios") ? true : false;

				$scope.onRadioChangedHandler = function (groupModel, item) {
					// 'item.selected' is not part of the field model, but added by the field itself to indicate selected radio or dropdown options
					// See individual `canopy-core:field-select` and `canopy-core:field-radio` directives
 
					if (!groupModel && !item.selected) {
						return;
					}

					// If you click an already selected single-assignment keyword (radio button) it should be de-selected as result....
					if (groupModel.id === item.id && !$scope.groupModelRequired) {
						$scope.groupModel = null;
						item.selected = false;
					}
				};
			},
			scope: {
				groupListItemLabelProperty: "@",
				groupModel: "=",
				groupModelRequired: "=",
				groupModelDisabled: "=",
				groupModelLabel: "=",
				groupList: "=",
				groupListItemDescription: "@"
			},
			templateUrl: Luma.paths.context + "/system/mantle/components/canopyCore/directives/form-field/canopy-form-field-single-select-group.template.html"
		};
	});
})(canopyCore);