
(function (canopyCore) {
	"use strict";

	canopyCore.directive("canopyCoreFieldToggle", function (controllerLinker, utilities) {
		return {
			restrict: "A",
			replace: true,
			require: "^canopyCoreFormContext",
			transclude: true,
			controller: function ($scope) {
				$scope.uuid = utilities.getUUID();

				$scope.validation = {
					active: false,
					passed: true,
					message: ""
				};

				$scope.reference = function () {
					return $scope.fieldLabel;
				};

				var onInit = function () {
					if ($scope.fieldModel === undefined) {
						$scope.fieldModel = $scope.fieldDefaultValue !== undefined || $scope.fieldDefaultValue !== null ? $scope.fieldDefaultValue : false;
					}
				};

				$scope.switchToDisable = function (element) {
					$scope.fieldDisabledOnSubmit = element ? true : false;
				};

				$scope.validate = function () {
					$scope.validation.active = true;
					$scope.validation.passed = true;
					$scope.validation.message = "";

					return $scope.validation.passed;
				};

				onInit();

				$scope.onSliderClicked = function () {
					$scope.fieldModel = !$scope.fieldModel;

					if (typeof $scope.onFieldChange === "function") {
						$scope.onFieldChange();
					} else if (typeof $scope?.onFieldChange?.fn === "function" && $scope?.onFieldChange?.data) {
						$scope.onFieldChange.fn($scope.onFieldChange.data, $scope.fieldModel);
					}
				};

				$scope.$watch("fieldModel", function (value) {
					if ($scope.validation.active) {
						$scope.validate();
					}
				});
			},
			scope: {
				fieldDefaultValue: "=",
				fieldDescription: "@",
				fieldDisabled: "=",
				fieldLabel: "@",
				fieldModel: "=",
				fieldTestId: "@",
				inlineLabel: "=?",
				inheritFontSize: "=?",
				onFieldChange: "<?",
				fieldLabelTooltip: "@"
			},
			link: controllerLinker,
			templateUrl: Luma.paths.context + "/system/mantle/components/canopyCore/directives/form-field/canopy-form-field-toggle.template.html"
		};
	});
})(canopyCore);
