(function (canopyCore, angular) {
	"use strict";

	canopyCore.filter("capitalize", function() {
		const isStartsWithAlpha = (str) => {
			const code = str.charCodeAt(0);
			return  (!(code > 64 && code < 91) && !(code > 96 && code < 123)) ? false : true;
		};
		return (text) => {
			if (!text) return text;
			const words = text.split(" ");
			return words.map(word => isStartsWithAlpha(word) ? word[0].toUpperCase() + word.substr(1).toLowerCase() : word).join(" ");
		};
	});

})(canopyCore, angular);