/**
 * WARNING
 * 
 * Any object values in the return here, that are changed will also need to be updated in GTM where the listeners are defined.
 * https://tagmanager.google.com/#/container/accounts/6000991820/containers/31152043/workspaces/11
 */
(function(canopyCore) {
	"use strict";

	canopyCore.factory("canopyEventProvider", function() {
		return {
			"NAVIGATION_END": "com.intrepia.canopy.event.navigationEnd",
			"PROJECT_CREATE": "com.intrepia.canopy.event.projectCreate",
			"PROJECT_CREATED": "com.intrepia.canopy.event.projectCreated",
			"WORKFLOW_TRANSITION": "com.intrepia.canopy.event.workflowTransition",
			"LIST_SEARCH_TERM": "com.intrepia.canopy.event.listSearchTerm",
			"ASSET_DETAIL": "com.intrepia.canopy.event.assetDetail",
			"PAGE_LOADING": "com.intrepia.canopy.event.pageLoading",
			"PAGE_LOADED": "com.intrepia.canopy.event.pageLoaded",
			"PAGE_VIEW": "com.intrepia.canopy.event.pageView",
		};
	});

})(canopyCore);
