(function (canopyCore, angular) {
	"use strict";

	canopyCore.filter("canopyDaysAgo", function (utilities) {
		return function (date) {
			let _today = moment();
			let _date;
			let _diffInDays;

			if (!date) return "-";

			if (angular.isDate(date)) {
				_date = moment(date);
			} else if (typeof date === "string") {
				_date = moment(utilities.getDateOnly(date));
			}

			if (_date.isBefore(_today)) {
				_diffInDays = _date.startOf("day").diff(_today.endOf("day"), "days");
			} else {
				_diffInDays = _date.endOf("day").diff(_today.startOf("day"), "days");
			}

			let output;

			/**
			 * _diffInDays
			 * positive number is in the future
			 * negative number is in the past
			 * 0 is today
			 * -1 is yesterday
			 * 1 is tomorrow
			 */


			if (_diffInDays < 0) {
				if (_diffInDays === -1) {
					output = "Yesterday";
				} else if (_diffInDays < -1) {
					output = Math.abs(_diffInDays) + " days ago";
				}
			} else {
				if (_diffInDays === 0) {
					output = "Today";
				} else if (_diffInDays === 1) {
					output = "Tomorrow";
				} else if (_diffInDays > 1) {
					output = "In " + _diffInDays + " days";
				}
			}



			return output;
		};
	});

})(canopyCore, angular);