(function (canopyCore, angular) {
	"use strict";

	canopyCore.filter("canopyDate", function ($filter, utilities) {
		return function (input, format, onlyDate) {
			var str = "";

			if (input) {
				let datePattern = "YYYY-MM-DD HH:mm:ss [GMT]ZZ";
				let dateParsed;
				
				if (onlyDate) {
					dateParsed = moment(utilities.getDateOnly(input));
				} else {
					dateParsed = moment(input, datePattern);	
				}

				if (!dateParsed.isValid()) {
					dateParsed = moment(input);
				}

				if (format) {
					str = dateParsed.format(format);
				}
				else {
					str = dateParsed.format("D") + " " + $filter("canopyLocalise")("core.nouns.month_" + dateParsed.format("MMMM").toLowerCase()) + " " + dateParsed.format("YYYY");
				}
			}

			return str;
		};
	});

})(canopyCore, angular);
