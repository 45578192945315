(function(canopyCore) {
	"use strict";

	canopyCore.service("controllerLinker", [function () {
		return function ($scope, $element, $attrs, $ctrl) {
			var externalInterface = {
				reference: function () {
					return $scope.reference();
				},
				validate: function () {
					return $scope.validate();
				},
				switchToDisable: function (element) {
					return $scope.switchToDisable(element);
				}
			};

			$ctrl.registerField(externalInterface);

			$scope.$on("$destroy", function () {
				$ctrl.deregisterField(externalInterface);
			});
		};
	}]);
})(canopyCore);
