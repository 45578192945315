(function (canopyCore, angular) {
	"use strict";

	canopyCore.filter("canopyFileSize", function () {
		return function (input, precision) {
			var str = "";

			if (input) {

				if (isNaN(parseFloat(input)) || !isFinite(input)) str = "-";

				if (typeof precision === "undefined") precision = 1;

				var units = ["bytes", "kB", "MB", "GB", "TB", "PB"],
					number = Math.floor(Math.log(input) / Math.log(1000));

				str = (input / Math.pow(1000, Math.floor(number))).toFixed(precision) + " " + units[number];
			}

			return str;
		};
	});

})(canopyCore, angular);