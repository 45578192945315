(function (canopyCore) {
	"use strict";

	canopyCore.directive("canopyCoreFieldHours", function (controllerLinker, utilities, canopyFormFields) {
		return {
			restrict: "A",
			replace: true,
			require: "^canopyCoreFormContext",
			transclude: true,
			controller: function ($scope) {
				
				$scope.uuid = utilities.getUUID();

				$scope.validation = {
					active: false,
					passed: true,
					message: ""
				};

				var allowedKeys = [8,9,37,38,39,40,46,110,190];

				$scope.onFieldBlur = function(e) {
					var val = parseFloat($scope.proxyModel);
					if (Number.isNaN(val)) {
						val = 0;
					}
					val = Math.ceil(val * 4) / 4;
					$scope.fieldModel = val;
					if ($scope.proxyModel === "") {
						proxyModelLock = true;
						$scope.proxyModel = "0";
						fieldModelLock = false;
					} else if (/\.$/.test($scope.proxyModel)) {
						proxyModelLock = true;
						$scope.proxyModel = $scope.proxyModel.replace(".","");
						fieldModelLock = false;
					}
				};

				$scope.onFieldKeydown = function(e) {
					var keyCode = e.which || e.keyCode;

					if (keyCode < 48 || keyCode > 58) {
						if (allowedKeys.indexOf(keyCode) === -1) {
							e.preventDefault();
						}
						if ((keyCode === 190 || keyCode === 110) && $scope.proxyModel.indexOf(".") !== -1) {
							e.preventDefault();
						}
						if (keyCode === 38) {
							$scope.up();
						}
						if (keyCode === 40) {
							$scope.down();
						}
					} else {

						var cPos = e.target.selectionStart;
						var highlight = e.target.selectionStart !== e.target.selectionEnd;

						if ($scope.proxyModel.indexOf(".") !== -1 && cPos > $scope.proxyModel.indexOf(".") && /\.[0-9]{2}$/.test($scope.proxyModel) && !highlight) {
							e.preventDefault();
						}
						
					}

				};

				function incrementModel(change) {
					var newVal = $scope.fieldModel + change;
					if ($scope.fieldMinValue !== undefined && newVal < parseFloat($scope.fieldMinValue)) {
						newVal = parseFloat($scope.fieldMinValue);
					} else if ($scope.fieldMaxValue !== undefined && newVal > parseFloat($scope.fieldMaxValue)) {
						newVal = parseFloat($scope.fieldMaxValue);
					}
					$scope.fieldModel = newVal;				
				}

				$scope.up = function() {
					if (!$scope.fieldDisabled) {
						incrementModel(0.25);
					}
				};

				$scope.down = function() {
					if (!$scope.fieldDisabled) {
						incrementModel(-0.25);
					}
				};

				$scope.validate = function () { 
					$scope.validation.active = true;
					$scope.validation.passed = true;
					$scope.validation.message = "";

					var value = $scope.fieldModel;

					if ($scope.fieldRequired && typeof value === "number") {
						if (value >= 0 && value === undefined || value.length === 0) {
							$scope.validation.passed = false;
							$scope.validation.message = canopyFormFields.getFieldValidationMsg(canopyFormFields.FIELD_VALIDATION_MSG_TYPE_REQUIRED);
						}
					} else if ($scope.fieldRequired) {
						if (!value && value === undefined || value.length === 0) {
							$scope.validation.passed = false;
							$scope.validation.message = canopyFormFields.getFieldValidationMsg(canopyFormFields.FIELD_VALIDATION_MSG_TYPE_REQUIRED);
						}
					}

					if (!$scope.validation.passed && typeof $scope.onValidationFailed === "function") {
						$scope.onValidationFailed();
					}

					return $scope.validation.passed;
				};

				function convertProxyToModel() {
					fieldModelLock = true;
					var newVal = parseFloat($scope.proxyModel);
					if ($scope.fieldMinValue !== undefined && newVal < parseFloat($scope.fieldMinValue)) {
						newVal = parseFloat($scope.fieldMinValue);
					} else if ($scope.fieldMaxValue !== undefined && newVal > parseFloat($scope.fieldMaxValue)) {
						newVal = parseFloat($scope.fieldMaxValue);
					}
					$scope.fieldModel = newVal;
				}

				function convertModelToProxy() {
					proxyModelLock = true;
					var newVal = parseFloat($scope.fieldModel);
					if (Number.isNaN(newVal)) {
						$scope.fieldModel = 0;
					} else if (Number.isInteger(newVal)) {
						$scope.proxyModel = String(newVal);
					} else {
						$scope.proxyModel = newVal.toFixed(2);
					}
				}

				var proxyModelLock = false;
				$scope.$watch("proxyModel", function (value) {
					
					if (!proxyModelLock && $scope.proxyModel !== "") {
						convertProxyToModel();
					}

					proxyModelLock = false;

				});


				var fieldModelLock = false;
				$scope.$watch("fieldModel", function (value) {

					if (!fieldModelLock) {
						convertModelToProxy();						
					}

					fieldModelLock = false;

					if ($scope.validation.active) {
						$scope.validate();
					}
					if ($scope.onFieldChange) {
						$scope.onFieldChange({ value: value });
					}

				});

				convertModelToProxy();

			},
			scope: {
				fieldModel: "=",
				fieldLabel: "@",
				fieldDescription: "@",
				fieldRequired: "=",
				fieldPlaceholder: "@",
				fieldDisabled: "=",
				fieldTestId: "@",
				fieldSuffix: "&?",
				fieldSpinner: "=?",
				fieldMinValue: "=?",
				fieldMaxValue: "=?",
				labelDecorator: "=",
				labelDecoratorVal: "=",
				onValidationFailed: "&?",
				onFieldChange: "&?"
			},
			link: controllerLinker,
			templateUrl: Luma.paths.context + "/system/mantle/components/canopyCore/directives/form-field/canopy-form-field-hours.template.html"
		};
	});
})(canopyCore);
