(function (canopyCore, angular) {
	"use strict";

	canopyCore.filter("extractStringFromMulti", function () {
		return function (input) {
			var str = input;
			var converter = "";
			var searchInInput = 0;

			if (input && input.length) {
				searchInInput = input.search("selected-options-concat");
				if (searchInInput > 0) { 
					try {
						converter = JSON.parse(input);
						return str = converter["selected-options-concat"];
					} catch (e) {
						console.error("Could not extract JSON from multi-select-options, please ensure the data stored is in JSON format");
						return str = input;
					}
					
				}
			}

			return str;
		};
	});

})(canopyCore, angular);