(function (canopyCore) {
	"use strict";

	canopyCore.directive("canopyCoreFormContext", function () {
		return {
			restrict: "A",
			controller: function ($scope, $element) {
				var _fields = [];

				this.registerField = function (field) {
					_fields.push(field);
				};

				this.deregisterField = function (field) {
					_fields = _fields.filter(function (element) {
						return element !== field;
					});
				};

				var switchFieldsToDisabledOnSubmit = function(element) {
					if ($scope.fieldsDisabledOnSubmit) {
						_fields.forEach(function(field) {
							field.switchToDisable(element);
						});
					}
				};

				$scope.$on("submit", function () {
					
					var valid = true;

					var validationReport = { valid: [], invalid: [] };

					_fields.forEach(function(field) {
						var fieldIsValid = field.validate();
						valid = valid && fieldIsValid;
						if (fieldIsValid) {
							validationReport.valid.push(field);
						} else {
							validationReport.invalid.push(field);
						}
					});

					if ($scope.callbacks) {
						if (valid) {
							if ($scope.callbacks.onValidationSuccess) {
								$scope.callbacks.onValidationSuccess();
							}

							if ($scope.callbacks.onSubmit) {
								switchFieldsToDisabledOnSubmit(true);
								$scope.callbacks.onSubmit($element.attr("form-id"));
							}
						}
						else {
							if ($scope.callbacks.onValidationFailure) {
								$scope.callbacks.onValidationFailure(validationReport);
							}
						}
					}
				});

				$scope.$on("switchFieldsToEnable", function() {
					switchFieldsToDisabledOnSubmit(false);
				});
			},
			scope: {
				callbacks: "=",
				fieldsDisabledOnSubmit: "="
			}
		};
	});
})(canopyCore);
