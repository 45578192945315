(function(canopyCore) {
	"use strict";

	canopyCore.factory("canopyDebug", function($location) {
		var getIsDebugMode = function() {
			return ($location.absUrl().indexOf("debug=true") !== -1) ? true : false;
		};
	
		var getIsLocalEnv = function() {
			return ($location.absUrl().indexOf("localhost/dev") !== -1 || 
			$location.absUrl().indexOf("localhost/luma") !== -1 || 
			$location.absUrl().indexOf("luma.storyteq.dev") !== -1 ||
			$location.absUrl().indexOf("localhost:88/dev") !== -1) ? true : false;
		};
		return {
			getIsDebugMode: getIsDebugMode,
			getIsLocalEnv: getIsLocalEnv
		};
	});

})(canopyCore);