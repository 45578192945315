canopyCore.service("canopyValidation", function ($injector, $filter) {
	var _validators = {
		"com.intrepia.email": function (input) { 
			var re =  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			
			if (input && input.match(re)) {
				return {
					passed: true
				};
			}
			else {
				if ($injector.has("canopyLocalise" + "Filter")) {
					return {
						passed: false,
						message: $filter("canopyLocalise")("core.info.invalid_email")
					};
				} else {
					return {
						passed: false,
						message: "Invalid email"
					};
				}
			}
		},
		"com.intrepia.password": function (input) {
			var hasUpperCase = input.replace(/[^A-Z]/g, "").length;
			var hasLowerCase = input.replace(/[^a-z]/g, "").length;
			var hasNumber    = input.replace(/[^0-9]/g, "").length;
			
			if (input.length >= 10 && hasUpperCase && hasLowerCase && hasNumber) {
				return {
					passed: true
				};
			}
			else {
				if ($injector.has("canopyLocalise" + "Filter")) {
					return {
						passed:  false,
						message: $filter("canopyLocalise")("core.info.invalid_password")
					};
				} else {
					return {
						passed:  false,
						message: "Passwords must contain at least one of each of the following; capital letter, lower-case letter, number and be at least 10 characters in length"
					};
				}
			}
			
		},
		"com.intrepia.number.natural": function (input) {
			if (Number.isInteger(input) && input > 0) {
				return {
					passed: true
				};
			}
			else {
				if ($injector.has("canopyLocalise" + "Filter")) {
					return {
						passed: false,
						message: $filter("canopyLocalise")("core.info.invalid_value")
					};
				} else {
					return {
						passed: false,
						message: "Invalid value"
					};
				}
			}
		}
	};
	
	return {
		setValidator: function (name, validator) {
			_validators[name] = validator;
		},
		getValidator: function (name) {
			return _validators[name];
		}
	};
});